/**
* Color
**/

// White
$white-0: #fff;
$white-1: #e8e8e8;
$white-2: #f9f9f9;

// Black
$black-0: #000;

// Gray
$gray-0: #dcdcdc;

// Blue
$blue-0: #007d95;
$blue-1: #002a41;
$blue-2: #cfebfa;
$blue-3: #49dde5;

//Yellow
$yellow-0: #f5a524;
$yellow-1: #ebe3bc;

//Gray
$gray-1: #bababa;
$gray-2: #b7b7b7;
$gray-3: #919191;
$gray-4: #7d7d7d;
$gray-5: #928888;
$gray-6: #848484;
$gray-7: #868686;
$gray-8: #d9d9d9;
$gray-9: #565656;
$gray-10: #bcbcbc;
$gray-11: #5d5d5d;
$gray-12: #adafb0;
$gray-13: #7a7a7a;
$gray-14: #f7f7f7;

//Red
$red-0: #c40000;
$red-1: #df2727;

//purple
$purple-0: #7678b7;

//green

$green-0: #46ba87;
