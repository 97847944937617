@import "src/assets/scss/colors";

body {
  margin: 0;
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Inter",
    "Marko One", monospace;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.shadow {
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 6px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  // background: $gray-15;
}
.full-page-slider {
  .slick-dots.slick-thumb {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 20px;
  }

  .slick-dots.slick-thumb li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: rgba(255, 255, 255, 0.33);
    margin: 0 4px;
  }

  .slick-dots.slick-thumb li.slick-active {
    background-color: white;
    width: 18px;
    border-radius: 10px;
  }
}

.btn-page-slider {
  .slick-dots.slick-thumb {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: -28px;
  }

  .slick-dots.slick-thumb li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: rgba(0, 42, 65, 0.2);
    margin: 0 4px;
  }

  .slick-dots.slick-thumb li.slick-active {
    background-color: #007d95;
    width: 18px;
    border-radius: 10px;
  }
  .slick-slide {
    padding: 4px 0;
    height: 100%;
    padding-left: 16px;
  }
  .slick-slide:last-child {
    padding-right: 16px;
  }
  .slick-list {
    height: 100% !important;
  }
}
a,
span {
  -webkit-tap-highlight-color: transparent;
}
